import React from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from "react-router-dom";

const ChatIcon = () => {
  return (
    <Link
      to="https://api.whatsapp.com/send?phone=+443301225960&text=I%27m%20interested%20in%20your%20product%20"
      target="_blank"
      rel="noopener noreferrer"
      className="animate__animated  animate__pulse animate__infinite shadow-lg cursor-pointer
                 bg-[#25D366] hover:bg-[#173303] text-white p-3 rounded-full
                 bottom-5 left-6 fixed
                 md:bottom-6 md:left-[30px]
                 lg:bottom-12 lg:left-[50px]"
    >
      <IoLogoWhatsapp size="30px" className="text-white hover:text-[#25D366]" />
    </Link>
  );
};

export default ChatIcon;
