import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RiArrowRightSLine } from "react-icons/ri";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import HeaderContainer from "../../../common/header-container/HeaderContainer";
import tick_mark from "./img/tick_mark.png";

const MonthlyPlan = ({ subscriptionType }) => {
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handlePrev = () => {
    if (swiperInstance) {
      swiperInstance.slidePrev();
    }
  };
  const handleNext = () => {
    if (swiperInstance) {
      swiperInstance.slideNext();
    }
  };
  const restaurant_pos = [
    {
      plan: "Lite",
      plan_description: "Unlimited orders",
      feesweek: `£ 19 `,
      strike_out_week: "",
      strike_out_year: "",
      feesyear: `£ 730`,
      transaction_fee: '1.29 %* Transaction Fee',
      order_processing: "Order processing :",
      order_processing_data: {
        para_1: "Unlimited forever",
        para_2: "Ordering in-store, online, via mobile, and by telephone.",
      },
      feature_title: "Features",
      feature_subtitle: "",
      feature: [
        "Online Website",
        "Deliveries & Takeaways ",
        "QR Ordering",
        "Payment Terminal",
        "Point of Sale Software",
        "Delivery Management",
        "Order Management",
        "Online Reservation",
      ],
      button: "Sign up now",
    },
    {
      plan: "Plus",
      plan_description: "Unlimited orders",
      feesweek: `£ 35 `,
      strike_out_week: "",
      strike_out_year: "",
      feesyear: `£ 1344`,
      transaction_fee: '0.79%* Transaction Fee',
      order_processing: "Order processing :",
      order_processing_data: {
        para_1: "Unlimited forever",
        para_2: "Ordering in-store, online, via mobile, and by telephone.",
      },
      feature_title: "Features",
      feature_subtitle: "Everything in Lite, plus",
      feature: [
        "Mobile App",
        "Mobile POS Order",
        "Digital Waiter Pads",
        "Digital Waiter Pads",
        "Customer Loyalty Management",
        "Customer Marketing Suite",
        "24/7 Chat Support",
        "Gift Vouchers",
      ],
      button: "Sign up now",
    },
    {
      plan: "Premium",
      plan_description: "Multi-location + Franchise",
      feesweek: `£ 40 `,
      strike_out_week: "",
      strike_out_year: "",
      feesyear: `£ 1536`,
      transaction_fee: '0.49%* Transaction Fee',
      order_processing: "Order processing :",
      order_processing_data: {
        para_1: "Unlimited forever",
        para_2: "Ordering in-store, online, via mobile, and by telephone.",
      },
      feature_title: "Features",
      feature_subtitle: "Everything in Plus, plus",
      feature: [
        "POS Hardware",
        "Location Grouping",
        "Multi-Language Software",
        "Multi-Location Menu Control",
        "Multi-Location Reporting Suite",
        "Central Data Sharing",
        "Franchisee Management",
        "24/7 Chat and Call Support",
      ],
      button: "Contact us",
    },
  ];

  return (
    <div>
      <HeaderContainer>
        <div
          className="mt-[30px] mb-5 
                     md:mt-20 md:mb-20 md:mx-16
                     lg:mx-0">

          {/* Desktop view  */}
          <div className="lg:grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 hidden">
            {restaurant_pos.map((res, index) => (
              <div
                key={index}
                className="flex flex-col bg-[#173303] p-8 rounded-[30px]
                           hover:scale-110 duration-1000  hover:border-4 
                           hover:border-[#9FE870]"
              >
                {res.plan === "Plus" &&
                  (<div className="mt-0">
                    <p
                      className="text-[18px] leading-[22px] bg-gradient-to-r from-[#6c5604] via-[#ECD0A2] to-[#6c5604] 
                               text-black font-work-sans rounded-full w-[180px] font-medium
                              text-center py-2 px-3"
                    >
                      {res.plan === "Plus" ? "Recommended" : ""}
                    </p>

                  </div>)
                }
                {res.plan !== "Plus" &&
                  (<div className="mt-3">
                    <p
                      className="text-[18px] leading-[22px] bg-gradient-to-r from-[#6c5604] via-[#ECD0A2] to-[#6c5604] 
                               text-black font-work-sans rounded-full w-[180px] font-medium
                              text-center py-2 px-3 invisible "
                    >
                      {res.plan !== "Plus" ? "" : ""}
                    </p>

                  </div>)
                }

                {/* plan */}
                <div className="mt-0">

                  <p
                    className="text-[32px] leading-[40px]
                               font-bold text-white font-work-sans
                               text-start"
                  >
                    {res.plan}
                  </p>
                  <p
                    className="text-[#9fe870] text-[20px] leading-[28px] text-nowrap mt-2
                                  font-dm-sans
                                  xl:text-[20px] xl:leading-[24px]
                                  "
                  >
                    {res.plan_description}
                  </p>
                </div>

                {/* fees */}
                <div className="my-2">
                  <h3 className="text-[32px] my-2 text-white inline">
                    <span className="text-lime-300 inline">
                      {subscriptionType === "monthly" ? (
                        <span className="inline">
                          <span className="text-[14px] line-through pr-2 text-[#fff]">
                            {res.strike_out_week}
                          </span>
                          {res.feesweek}
                          <span className="text-sm">&nbsp; include VAT</span>
                        </span>
                      ) : (
                        <span className="inline"> {res.feesyear}
                          <span className="text-sm">&nbsp; include VAT</span></span>
                      )}
                    </span>{" "}

                    {subscriptionType === "monthly" ? (
                      <p className="inline text-[28px]">
                        <br />
                        {"per week"}
                      </p>
                    ) : (
                      <p className="inline text-[24px]">
                        <br />
                        {"per year (save 18%)"}
                      </p>
                    )}

                  </h3>
                </div>

                {/* order_processing */}
                <div className="">
                  <p
                    className="text-[18px] leading-[34px] text-white font-work-sans
                                my-3 lg:my-0 2xl:my-3
                               xl:text-[18px] xl:leading-[30px] "
                  >
                    {res.transaction_fee}
                  </p>

                  <p
                    className="text-[20px] leading-[34px] text-white font-work-sans
                               font-bold my-3 lg:my-0 2xl:my-3
                               xl:text-[24px] xl:leading-[30px] "
                  >
                    {res.order_processing}
                  </p>
                  <p
                    className="text-[20px] leading-[34px] text-[#9fe870]  font-work-sans
                               font-medium 
                               xl:text-[20px] xl:leading-[28px]"
                  >
                    {res.order_processing_data.para_1}
                  </p>
                  <p
                    className="text-[20px] leading-[34px] text-white font-work-sans my-1
                               xl:text-[20px] xl:leading-[28px]"
                  >
                    {res.order_processing_data.para_2}
                  </p>
                </div>

                {/* feature_title */}
                <div className="mt-5">
                  <p
                    className="text-[20px] leading-[30px] text-white font-work-sans
                               font-bold 
                               xl:text-[24px] xl:leading-[30px]"
                  >
                    {res.feature_title} :
                  </p>
                  <p
                    className="text-[18px] leading-[30px] text-white font-work-sans my-3
                               xl:text-[18px] xl:leading-[30px]"
                  >
                    {res.feature_subtitle}
                  </p>
                </div>

                {/* title5 */}
                {res.feature.map((list, index) => (
                  <div className="my-2  key={index}">
                    <img
                      src={tick_mark}
                      title=" POS software cost" alt=" POS software cost"
                      className="inline pr-3"
                    />
                    <p
                      className="text-[18px] leading-[24px] text-white font-work-sans inline
                                 xl:text-[16px] xl:leading-[20px] capitalize
                                 2xl:text-[18px] 2xl:leading-[24px]
                                 "
                    >
                      {list}
                    </p>
                  </div>
                ))}

                {/* button */}
                <div className="mt-5 flex justify-start">
                  <Link to="/contactus"
                    className="
                   p-2.5 px-[15px] bg-[#9fe870] text-[#173303] text-[18px]
                   font-semibold rounded-[10px] font-dm-sans leading-[30px] duration-1000
                   hover:text-[#9fe870] hover:bg-[#173303] shadow-lg"
                  >
                    {res.button}
                    <RiArrowRightSLine
                      size={"30px"}
                      className="inline pb-0.5"
                    />
                  </Link>
                </div>
              </div>
            ))}
          </div>

          {/* Mobile view  */}
          <div className="block lg:hidden -z-50">
            <Swiper
              slidesPerView="auto"
              effect={"fade"}
              loop={"true"}
              modules={[Pagination, Autoplay]}
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              pagination={{ clickable: true }}
              autoplay={{
                delay: 5500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                421: {
                  slidesPerView: 1,
                },
                576: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 1,
                },
                992: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 3,
                },
                1400: {
                  slidesPerView: 3,
                },
                1600: {
                  slidesPerView: 3,
                },
                1800: {
                  slidesPerView: 3,
                },
                2000: {
                  slidesPerView: 3,
                },
                2500: {
                  slidesPerView: 3,
                },
              }}
            >
              {
                restaurant_pos.sort((a, b) => (a.plan === "Plus" ? -1 : 1)) // Sort to place "Plus" first
                  .map((res, index) => (
                    <SwiperSlide key={index}>
                      <div
                        key={index}
                        className="flex flex-col bg-[#173303] px-5 pt-3 pb-5 md:p-8 rounded-[30px]"
                      >
                        {/* plan */}
                        <div className="mt-5 md:mt-5">
                          {res.plan === "Plus" &&
                            <div className="mt-5">
                              <p
                                className="text-[14px] leading-[20px] bg-gradient-to-r from-[#6c5604] via-[#ECD0A2] to-[#6c5604] 
                                text-black font-work-sans rounded-full w-[135px] font-medium mt-0 mb-5
                               text-center py-2 "
                              >
                                {res.plan === "Plus" ? "Recommended" : ""}
                              </p>

                            </div>
                          }
                          <p
                            className="text-[28px] leading-[28px]
                               font-bold text-white font-work-sans
                               text-start
                               md:text-[32px] md:leading-[40px]
                               "
                          >
                            {res.plan}
                          </p>
                          <p
                            className="text-white text-[20px] leading-[28px] text-nowrap mt-2
                                  font-dm-sans
                                  xl:text-[20px] xl:leading-[24px]
                                  "
                          >
                            {res.plan_description}
                          </p>
                        </div>

                        <div className="my-2">
                          <h3 className="text-[32px] my-2 text-white inline">
                            <span className="text-lime-300 inline">
                              {subscriptionType === "monthly" ? (
                                <span className="inline">
                                  <span className="text-[14px] line-through pr-2 text-[#fff]">
                                    {res.strike_out_week}
                                  </span>
                                  {res.feesweek}
                                </span>
                              ) : (
                                <span className="inline"> {res.feesyear}</span>
                              )}
                            </span>{" "}
                            {subscriptionType === "monthly" ? (
                              " per week"
                            ) : (
                              <p className="inline text-[24px]">
                                {" per year (save 18%)"}
                              </p>
                            )}
                          </h3>
                        </div>

                        {/* order_processing */}
                        <div className="mt-2 md:mt-5">
                          <p
                            className="text-[18px] leading-[34px] text-white font-work-sans
                                my-3 lg:my-0 2xl:my-3
                               xl:text-[18px] xl:leading-[30px] "
                          >
                            {res.transaction_fee}
                          </p>
                          <p
                            className="text-[20px] leading-[24px] text-white font-work-sans
                               font-bold my-3
                               xl:text-[24px] xl:leading-[30px] "
                          >
                            {res.order_processing}
                          </p>
                          <p
                            className="text-[18px] leading-[24px] text-white font-work-sans
                               font-medium 
                               xl:text-[20px] xl:leading-[28px]"
                          >
                            {res.order_processing_data.para_1}
                          </p>
                          <p
                            className="text-[18px] leading-[24px] text-white font-work-sans my-1
                               xl:text-[20px] xl:leading-[28px]"
                          >
                            {res.order_processing_data.para_2}
                          </p>
                        </div>

                        {/* feature_title */}
                        <div className="mt-2 md:mt-5">
                          <p
                            className="text-[20px] leading-[30px] text-white font-work-sans
                               font-bold 
                               xl:text-[24px] xl:leading-[30px]"
                          >
                            {res.feature_title} :
                          </p>
                          <p
                            className="text-[18px] leading-[30px] text-white font-work-sans my-3
                               xl:text-[18px] xl:leading-[30px]"
                          >
                            {res.feature_subtitle}
                          </p>
                        </div>

                        {/* title5 */}
                        {res.feature.map((list, index) => (
                          <div className="my-2 key={index}">
                            <img
                              src={tick_mark}
                              title=" POS software cost" alt=" POS software cost"
                              className="inline pr-3 "
                            />
                            <p
                              className="text-[16px] leading-[20px] text-white font-work-sans inline
                                 xl:text-[18px] xl:leading-[24px] capitalize"
                            >
                              {list}
                            </p>
                          </div>
                        ))}

                        {/* button */}
                        <div className="mt-5 flex justify-start">
                          <Link to="/contactus">
                            <button
                              className="
                   p-2.5 px-[15px] bg-[#9fe870] text-[#173303] text-[18px]
                   font-semibold rounded-[10px] font-dm-sans leading-[30px] duration-1000
                   hover:text-[#9fe870] hover:bg-[#173303] shadow-lg"
                            >
                              {res.button}
                              <RiArrowRightSLine
                                size={"30px"}
                                className="inline pb-0.5"
                              />
                            </button>
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
              }
            </Swiper>
          </div>

        </div>
      </HeaderContainer>
    </div>
  );
};

export default MonthlyPlan;
